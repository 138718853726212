const USERNAME = process.env.VUE_APP_USERNAME_MQTT;
const PASSWORD = process.env.VUE_APP_PASSWORD_MQTT;
const PORT = process.env.VUE_APP_PORT_MQTT;

export const optionsMqtt = {
  host: "iotm.helpmedica.com",
  port: PORT,
  endpoint: "/mqtt",
  clean: true,
  connectTimeout: 5000,
  reconnectPeriod: 5000,
  username: USERNAME,
  password: PASSWORD
}