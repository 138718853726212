const API_URL = process.env.VUE_APP_API_URL;
const API_VERSION = process.env.VUE_APP_API_VERSION;

const API_ROUTES = {
  auth: {
    login: `${API_URL}/api/login`,
  },
  brand: {
		get: `${API_URL}/${API_VERSION}/company/:customer_id/brands`,
	},
	service: {
		get: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service`,
	},
	area: {
		get: `${API_URL}/${API_VERSION}/company/filters/area`,
	},
	subarea: {
		get: `${API_URL}/${API_VERSION}/company/area/:area_id/subarea`,
	},
	locationPatient: {
		get: `${API_URL}/${API_VERSION}/company/filters/sub-area/:area_id`,
		save: `${API_URL}/${API_VERSION}/company/location-patient`,
		find: `${API_URL}/${API_VERSION}/company/location-patient/:id`
	},
	device: {
		get: `${API_URL}/${API_VERSION}/brand/:id/device`,
	},
	order: {
		get: `${API_URL}/${API_VERSION}/menu-order`,
	},
	altaInfo: {
		update: `${API_URL}/${API_VERSION}/company/location-patient/:id/departure-info`,
	},
	altaFisico: {
		update: `${API_URL}/${API_VERSION}/company/location-patient/:id/departure`,
	},
	examByDate: {
		get: `${API_URL}/${API_VERSION}/exam-patient/customer/filter`,
	},
	client: {
		find: `${API_URL}/${API_VERSION}/company`,
	},
}

export { API_ROUTES }